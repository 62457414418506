<script lang="js">
import { RouterLink } from 'vue-router';

export default {
    name: 'BaseNavbar',
    data() {
        return {}
    },
    methods: {},
    mounted() {},
    computed: {},
    components: {RouterLink},
    created: function () {}
}
</script>

<template>
  <nav class="navbar navbar-expand-lg sticky-top navbar-transparent px-5">
    <div class="container-fluid">
      <RouterLink class="navbar-brand" to="/">
        <img
          src="@/assets/logo.png"
          alt="Logo"
          class="d-inline-block align-text-top"
          width="70"
        />
      </RouterLink>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item px-md-2">
            <RouterLink class="nav-link fcp-nav" to="/">Home</RouterLink>
          </li>
          <li class="nav-item px-md-2">
            <RouterLink class="nav-link fcp-nav" to="/about"
              >About Us</RouterLink
            >
          </li>
          <li class="nav-item dropdown px-md-2">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              Academics
            </a>
            <ul class="dropdown-menu text-centerr border-0 rounded-0 shadow-lg">
              <li>
                <a class="dropdown-item disabled" target="" href="#">
                  Calendars
                </a>
              </li>
              <li class="dropstart">
                <a
                  class="dropdown-item dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  Schools
                </a>
                <ul class="dropdown-menu border-0 rounded-0 shadow-lg">
                  <li class="dropdown">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      School of Engineering
                    </a>
                    <ul class="dropdown-menu border-0 rounded-0 shadow-lg">
                      <li>
                        <a class="dropdown-item disabled" href="#">
                          ND Business Administration and Management
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      School of Management
                    </a>
                    <ul class="dropdown-menu border-0 rounded-0 shadow-lg">
                      <li>
                        <a class="dropdown-item disabled" href="#">
                          ND Electrical Engineering
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item disabled" href="#">
                          ND Computer Engineering
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li class="dropdown">
                    <a
                      class="dropdown-item dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      School of Science
                    </a>
                    <ul class="dropdown-menu border-0 rounded-0 shadow-lg">
                      <li>
                        <a class="dropdown-item disabled" href="#">
                          ND Computer Science
                        </a>
                      </li>
                      <li>
                        <a class="dropdown-item disabled" href="#">
                          ND Statistics
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <a class="dropdown-item disabled" href="#"> Careers </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown px-md-2">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Admissions
            </a>
            <ul class="dropdown-menu text-centerr border-0 rounded-0 shadow-lg">
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/Default"
                >
                  How to apply
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/FreshApplication"
                >
                  New applications
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://onlineapp.firstcitypoly.edu.ng/OnlineApp/ApplicantLogin"
                >
                  Applicant Portal
                </a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a
                  class="dropdown-item"
                  target="_blank"
                  href="https://onlineapp.firstcitypoly.edu.ng/Agents"
                >
                  Agent login
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown px-md-2">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Portal
            </a>
            <ul class="dropdown-menu text-center border-0 rounded-0 shadow-lg">
              <li><a class="dropdown-item disabled" href="#">Student</a></li>
              <li><a class="dropdown-item disabled" href="#">Staff</a></li>
            </ul>
          </li>
          <li class="nav-item px-md-2">
            <RouterLink class="nav-link fcp-nav" to="/contact"
              >Contact Us</RouterLink
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
nav a {
  font-weight: normal;
  color: #374c91;
}

nav a.router-link-exact-active {
  font-weight: 550;
  color: #e9b500;
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0.5); /* 50% transparency */
}

/* Default button styles */
.fcp-nav {
  color: #374c91; /* Default text color */
  cursor: pointer;
  font-size: 16px;
  font-weight: 400; /* Normal text weight */
  /* line-height: 45px; */
  /* margin: 0 0 2em; */
  /* max-width: 160px; */
  position: relative;
  text-decoration: none;
  text-transform: capitalize;
  width: 100%;
  border: none; /* Remove default border */
  background: none; /* No background */
}

/* Default state with no stroke */
.fcp-nav::before,
.fcp-nav::after {
  content: "";
  position: absolute;
  border: 1px solid transparent; /* Invisible stroke by default */
  width: 15px;
  height: 15px;
  transition: all 1.35s linear;
}

.fcp-nav::before {
  top: 0;
  left: 0;
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom: none;
  border-right: none;
}

.fcp-nav::after {
  bottom: 0;
  right: 0;
  border-bottom-width: 1px;
  border-right-width: 1px;
  border-top: none;
  border-left: none;
}

/* Hover state: transition to full stroke and bold text */
.fcp-nav:hover {
  font-weight: 550;
  text-decoration: none; /* No underline */
  color: #374c91;
}

.fcp-nav:hover::before,
.fcp-nav:hover::after {
  width: 100%;
  height: 100%;
  border-color: #e9b500; /* Change stroke color to #e9b500 */
  border-width: 1px; /* Full border */
}

/* Active state: top-left and bottom-right stroke, text color #e9b500 */
.fcp-nav:active,
nav a.router-link-exact-active {
  font-weight: 550;
  color: #e9b500;
}

.fcp-nav:active::before,
nav a.router-link-exact-active::before,
.fcp-nav:active::after,
nav a.router-link-exact-active::after {
  border-color: #e9b500;
  border-width: 1px;
}

/* Reset the stroke to only top-left and bottom-right when active */
.fcp-nav:active::before,
nav a.router-link-exact-active::before,
.fcp-nav:active::after,
nav a.router-link-exact-active::after {
  width: 15px;
  height: 15px;
}

/* Transition effect */
.fcp-nav,
.fcp-nav::before,
.fcp-nav::after {
  transition: all 0.35s ease;
}
</style>
