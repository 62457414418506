<script lang="js">
// @ is an alias to /src
import Carousel from "@/components/Carousel.vue";
import QuickLinks from "@/components/QuickLinks.vue";

export default {
  name: "HomeView",
  components: {
    Carousel,
    QuickLinks,
  },
};
</script>

<template>
  <div class="home">
    <div class="container-fluid text-white">
      <div class="row position-relative">
        <Carousel class="px-0" />
      </div>
    </div>

    <div class="container py-5">
      <div class="clearfix text-start fs-5">
        <img
          width="300"
          height="300"
          src="https://placehold.co/300"
          class="rounded-circle shadow-lg float-md-start mb-3 ms-md-3 me-5 img-md-center object-fit-cover"
          alt="FCP Rector"
          v-scrollanimation.once="'fadeIn'"
        />
        <h5
          class="card-title fw-bold"
          v-scrollanimation.once="'lightSpeedInRight'"
        >
          Message from Rector
        </h5>
        <div
          class="yellow-dash"
          v-scrollanimation.once="'lightSpeedInRight'"
        ></div>
        <p v-scrollanimation.once="'fadeInUp'">
          It is my pleasure to welcome you to First City Polytechnic, Abeokuta.
          We are pleased to invite you to join us as we build a new generation
          for a new Nigeria. Technology and Innovation is our goal and we hope
          to achieve this through consistent practical based training and
          inventions.
        </p>
        <img
          src="@/assets/img/signboard.png"
          width="400"
          class="float-md-end mb-3 ms-md-3 school-bld img-md-center"
          alt="school building"
          v-scrollanimation.once="'fadeInRight'"
        />
        <p v-scrollanimation.once="'fadeInUp'">
          Established in 2022, the Polytechnic has passed through many stages of
          approval and accreditation process. The Polytechnic currently have
          five fully accredited programmes and we are in the process of
          acquiring more programmes.
        </p>
        <p v-scrollanimation.once="'fadeInUp'">
          At First City Polytechnic, we are dedicated to empowering students
          with the skills, knowledge, and experience needed to excel in today’s
          dynamic and ever-evolving world. As a leading polytechnic, we pride
          ourselves on offering hands-on, industry-focused education that
          prepares our graduates for real-world challenges.
        </p>
        <p v-scrollanimation.once="'fadeInUp'">
          Why Choose FirstCity Polytechnic?
        </p>
        <ol class="numbered" v-scrollanimation.once="'fadeInUp'">
          <li>Industry-Aligned Programs.</li>
          <li>Experienced Lecturers and Instructors.</li>
          <li>State-of-the-Art Facilities</li>
          <li>A Supportive Community.</li>
        </ol>
      </div>
    </div>

    <QuickLinks v-scrollanimation.once="'slideInUp'" />
  </div>
</template>

<style scoped>
/* @media (min-width: 992px) {
  .school-bld {
    width: 30% !important;
  }
} */

@media (max-width: 768px) {
  .img-md-center {
    display: block !important;
    margin-right: auto !important;
    margin-left: auto !important;
  }
}
</style>
