import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/style.css";
import "animate.css";
import scrollanimation from "./v-scrollanimation";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  .directive("scrollanimation", scrollanimation)
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
