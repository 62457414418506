<script lang="js">

export default {
  components: {},
};
</script>

<template>
  <div class="container my-5">
    <div class="p-5 text-center fcp-bg-primary rounded-3 text-white">
      <h1 class="text-white">Interested in joining us?</h1>
      <div class="yellow-dash"></div>
      <p class="lead px-md-5 text-break">
        We would love to hear from you! Please send your resume and a brief
        cover letter to
        <a
          href="mailto:careers@firstcitypolytechnic.edu.ng"
          class="link-light link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
        >
          careers@firstcitypolytechnic.edu.ng</a
        >. Let's work together to shape the future of education.
      </p>
    </div>
  </div>
</template>

<style scoped></style>
